import '/assets/styles/pages/faq-detail.scss';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// // Context
import { useGlobalState } from 'pstv-commerce-tools/utilities/global-state';
// import { useDeliveryInfo } from '/controllers/delivery';
// import { useModals } from 'pstv-commerce-tools/utilities/modals';

// // Partials
import Loader from '/views/partials/loader'
import Placeholder from '/views/partials/placeholder'
import Collapser from '/views/partials/collapser';
import Link from 'pstv-commerce-tools/partials/link';
import Icon from 'pstv-commerce-tools/partials/icon';

// // Functions
// import removeFromArray from 'pstv-commerce-tools/functions/remove-from-array'

// Services
import siteServices from '/services/site'

const getFaqDetail = (faqID) => {
	return siteServices.getFaqDetail(faqID);
}

const FaqDetail = ({ pageProps, match }) => {
	const faqID = match?.params?.id;

	const { setNotFound } = useGlobalState();

	const [data, setData] = useState(pageProps?.faqData ?? false);

	useEffect(() => {
		if(!data && faqID) {
			getFaqDetail(faqID).then((faqData) => {
				setData(faqData);
			}).catch(() => {
				setNotFound();
			})
		}
	}, [data, faqID])

	return (
		<>
			<header className="faq-detail-header">
				<Link href="faqs" className="header-backbtn">
					<Icon name="angle-left" />
				</Link>
				{data?.name ?
					<h1 className="header-title">{data.name}</h1>
					:
					<Placeholder className="header-title" />
				}
			</header>
			<section className="account-content account-info loader-container">
				<Loader loading={!data} />
				{data?.faqs &&
					<ul className="faq-detail-list">
						{data.faqs.map((faq) => (
							<li className="list-faq" key={faq.id}>
								<Collapser
									wysiwyg
									className="faq-dropdown"
									html={faq.answer}
									title={faq.question}>
								</Collapser>
							</li>
						))}
					</ul>
				}
			</section>
		</>
	)
}

FaqDetail.propTypes = {
	match: PropTypes.object,
	pageProps: PropTypes.object,
}

FaqDetail.getServerProps = ({ match }) => {
	return new Promise((resolve) => {
		Promise.all([
			getFaqDetail(match.params.id)
		]).then(([faqData]) => {
			resolve({
				pageProps: {
					faqData
				},
			})
		}).catch(() => {
			resolve({
				status: 500,
			})
		})
	})
}


export default FaqDetail
